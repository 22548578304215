//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ctrlOrder } from "@/controller";
import { GoodsItem } from "@/components";
import { Button } from "element-ui"
export default {
    components: {
        [Button.name]:Button,
        GoodsItem
    },
    props: {
        nbr: String,
    },
    data() {
        return {
            list: [],
        };
    },
    computed: {
        disabled() {
            return this.list.every((item) => !item.isChecked);
        },
    },
    created() {
        this.getAfter();
    },
    mounted() {
        // this.$nextTick(() => {
        //     this.getAfter();
        // });
    },
    methods: {
        //獲取退貨列表
        async getAfter() {
            this.list = await ctrlOrder.getAfter({ SoNbr: this.nbr });
            const item = this.list.find((item) => item.isChecked);
            this.checkboxChange(item);
        },
        checkboxChange(item) {
            //主商品
            if (item.kind == 0) {
                //如果主商品當前數量 = 原始數量
                if (item.qtyOrd == item.currNum) {
                    for (const element of this.list) {
                        if (element.parent == item.ln) {
                            item.isChecked && this.$set(element, "isChecked", item.isChecked);

                            this.$set(element, "disabled", item.isChecked);
                            this.$set(element, "currNum", element.qtyOrd);
                        }
                    }
                }
            }
        },
        stepperChange(value, item) {
            //主商品
            if (item.kind == 0) {
                //如果主商品當前數量 = 原始數量
                if (item.qtyOrd == value) {
                    for (const element of this.list) {
                        if (element.parent == item.ln) {
                            if (item.isChecked) {
                                this.$set(element, "isChecked", item.isChecked);
                                this.$set(element, "disabled", true);
                                this.$set(element, "currNum", element.qtyOrd);
                            }
                        }
                    }
                } else {
                    for (const element of this.list) {
                        if (element.parent == item.ln) {
                            item.isChecked && this.$set(element, "disabled", false);
                        }
                    }
                }
            }
        },
        async sureEmit() {
            const list = this.list.filter((item) => item.isChecked && item.kind != 1 && item.kind != 3);
            let obj = {};
            let retList = [];
            for (const item of list) {
                obj = {
                    ln: item.ln,
                    qtyRet: item.currNum,
                };
                retList.push(obj);
            }
            const isAll = this.list.filter((item) => item.kind != 1 && item.kind != 3).every((item) => item.qtyOrd == item.currNum && item.isChecked)
            const value = {
                SodList: retList,
                soNbr: this.nbr,
            };
            await ctrlOrder.getPartRetResult(value,isAll);
        },
    },
};
